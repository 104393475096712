<template>
  <div id="balance">
    <div class="balance-bg">
      <div class="balance-details">
        <div class="balance-details-content"  @click="consume">
          消费明细
          <img
            src=""
            alt=""
          />
        </div>
      </div>
      <div class="balance-residue" >
        <span  class="balance-residue-content" > ￥1000.00 </span><br />
        <span class="balance-residue-txt" > 当前余额 </span>
      </div>
      <img
        src="	https://d1icd6shlvmxi6.cloudfront.net/gsc/OL5ASK/0…8b96f99d17fd009a16dcc803071cda1940dce4576b4b2b741"
        alt=""
      />
    </div>
    <div class="balance-con">
      <p>
        <span>余额充值</span>
      </p>
      <van-cell-group>
        <van-field v-model="value" placeholder="请输入本次需要充值的金额" />
      </van-cell-group>
    </div>
    <div class="balance-footer">
      <button class="balance-btn">立即充值</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  methods: {
    consume() {
      this.$router.push("/consume");
    },
  },
};
</script>

<style lang="less">
#balance {
  width: 100%;
  height: 100%;
  .balance-bg {
    width: 100%;
    height: 180px;
    background-color: rgba(245, 108, 108, 1);
    .balance-details {
      font-family: "微软雅黑";
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
      color: #ffffff;
      display: flex;
      justify-content: right;
      text-align: right;
      .balance-details-content{
        margin-top: 12px;
        margin-left: 90px;
        img {
          width: 5%; 
          margin-left: 6px
        }
      }
    }
    .balance-residue{
      text-align: center; 
      color: #ffffff; 
      margin-top: 40px;
      .balance-residue-content{
        font-size: 28px; font-weight: 700
      }
      .balance-residue-txt{
        font-size: 14px; 
        font-weight: 400
      }
    }
  }
  .balance-con {
    p {
      font-family: "Arial-BoldMT", "Arial Bold", "Arial";
      font-weight: 700;
      font-style: normal;
      font-size: 16px;
      text-align: left;
      line-height: 24px;
      margin-left: 20px;
    }
  }
  .balance-footer {
    display: flex;
    align-items: center;
    margin-top: 300px;
    .balance-btn {
      width: 96%;
      border: 0;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 700;
      border-radius: 5px;
      height: 50px;
      line-height: 20px;
      color: #fff;
      background-color: rgba(245, 108, 108, 1);
    }
  }
}
</style>
